.ant-layout-content {
  margin: 10px;
  padding: 30px;
  background-color: #ffffff;
}

.ant-typography.info {
  color: #ffffff;
  font-size: 16px;
}

@media (min-width: 414px) {
  .ant-layout-content {
    margin: 18px;
    padding: 30px 20px;
    background-color: #ffffff;
    border-radius: 6px;
  }

  .ant-typography.title {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 30px;
  }

  .ant-radio-button-wrapper {
    width: 50%;
    text-align: center;
  }

  .ant-checkbox-wrapper {
    font-size: 16px;
  }
}
