.center {
  text-align: center;
}

.textOnCenter {
  text-align: center;
}

.borderGrey {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  padding: 24px;
}

.borderGreyAllColor {
  background-color: #fafafa;
  padding: 16px;
  border-radius: 4px;
}

.spanBolt {
  font-weight: bold;
}

.imageSuccess {
  height: 70px;
  margin-bottom: 8px;
  width: 70px;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.textMoveLeft {
  padding-left: 24px;
}

.headOfTextMoveLeft {
  padding-left: 8px;
}
